<template>
	<el-row :gutter="160">
        <el-col :span="24" style="text-align: left;">
            <el-dropdown @command="onSelectGroup">
                <el-button type="success" style="width:144px;">
                    {{curGroupLabel}}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="item in serverGroups" :key="item.value" :command="item" icon="el-icon-coin">
                        {{item.label}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
			<el-button type="primary" icon="el-icon-notebook-1" @click="onClickWordMgr" style="margin-left: 8px;">词库管理</el-button>
		</el-col>
		<el-col :span="24">
			<el-table ref="multipleTable" :data="serverRows" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
			:cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column prop="id" label="id号"> </el-table-column>
                <el-table-column prop="name" label="servername" show-overflow-tooltip> </el-table-column>
                <el-table-column prop="title" label="显示名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="state" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag :type="stateTagFormatColor(scope.row.state)"
                            :effect="stateTagFormatEffect(scope.row.state)">{{stateTagFormatTxt(scope.row.state)}}
                        </el-tag>
                    </template>
                </el-table-column>   
                <el-table-column prop="playeronlines" label="在线人数" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="256">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickChatLogHis(scope.row)" type="primary" icon="el-icon-date" size="small">聊天记录</el-button>
                        <el-button @click.native.prevent="onClickChatLogNow(scope.row)" type="success" icon="el-icon-chat-dot-square" size="small">实时聊天</el-button>
                    </template>
                </el-table-column>
			</el-table>
		</el-col>
        <!-- ========日志弹框BEGIN====== -->
        <el-dialog :title="formTitle" :visible.sync="dialogLogForm" @close="onCloseLog" width="60%"
            :close-on-click-modal="false" center class="itemdialog">
            <el-row>
            <el-col :span="24" style="text-align: left;">
            <el-select v-model="formLog.channel" style="width: 200px;">
                <el-option v-for="item in channels" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <el-date-picker type="datetimerange" :editable="false" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" v-model="formLog.queryDate"
                :picker-options="pickerOptions" style="margin-left: 8px;" v-if="!realtimeChatLog">
            </el-date-picker>
            <el-button type="primary" icon="el-icon-search" @click="onClickQueryLog" style="margin-left: 8px;" v-if="!realtimeChatLog">查询</el-button>
            </el-col>
            <el-col :span="24">
            <el-table :data="chatLog" height="500px" style="margin-top: 8px;width: 100%" :cell-style="{padding:4+'px'}"
                class="serverlist" ref="dialogTable" :row-class-name="logRowClassName">
                <el-table-column prop="id" label="id" width="100"></el-table-column>
                <el-table-column prop="chattime" label="时间" width="200"></el-table-column>                
                <el-table-column label="玩家" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.nickname+'('+scope.row.cid+')'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="word" label="内容" show-overflow-tooltip></el-table-column>
                <el-table-column label="标记" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.flag===2">欺诈</span>
                        <span v-if="scope.row.flag===3">敏感</span>
                        <span v-if="scope.row.flag===0">禁言</span>
                        <span v-if="scope.row.flag===1">正常</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handlePageChatLog"
              layout="total, prev, pager, next"
              background
              :page-size="1000"
              :total="totalChatLog"
              v-if="!realtimeChatLog">
            </el-pagination>  
            </el-col>
            </el-row>
        </el-dialog>
        <!-- ========日志弹框END====== -->
        <!-- ========词库管理BEGIN====== -->
        <el-dialog title="词库管理" :visible.sync="dialogWordMgr" @click="onClickWordMgr" width="960px"
            :close-on-click-modal="false" center class="itemdialog">
            <el-row>
            <el-col :span="24" style="text-align: left;">
            <el-select v-model="curSenWordType" style="width: 200px;" @change="onSelectSenwordType">
                <el-option v-for="item in ntypes" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <el-input v-model="curKeyWord" placeholder="关键字过滤" style="width: 200px;margin-left: 8px;" @change="onSelectSenwordType"></el-input>
            <el-button type="success" icon="el-icon-plus" @click="onClickAddSenWord" style="margin-left: 120px;">添加新词条</el-button>
            </el-col>
            <el-col :span="24">
            <el-table :data="senwords" height="500" style="margin-top: 8px;width: 100%" :cell-style="{padding:4+'px'}"
                class="serverlist" border>
                <el-table-column prop="id" label="id" show-overflow-tooltip></el-table-column>
                <el-table-column prop="word" label="词条" show-overflow-tooltip></el-table-column>  
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                 <el-table-column label="操作" width="120px">
                     <template slot-scope="scope">
                         <el-button @click.native.prevent="onClickDelSenword(scope.row)" type="primary" icon="el-icon-delete" size="small">删除</el-button>
                     </template>
                 </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleCurrentChange"
              layout="total, prev, pager, next"
              background
              :page-size="200"
              :total="totalSenword">
            </el-pagination>            
            </el-col>
            </el-row>
        </el-dialog>
        <!-- ========词库管理END====== -->
        <el-dialog title="添加词条" :visible.sync="dialogAddSenWord" :close-on-click-modal="false" width="640px" center>
            <el-form :model="formWord">
                <el-form-item label="词条类型" label-width="80px">
                    <el-select v-model="formWord.flag" style="width: 100%;">
                        <el-option v-for="item in ntypes" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item> 
                <el-form-item label="词条内容" label-width="80px">
                    <el-input v-model="formWord.words" placeholder="词库,多个词条请分行,每行一条" :rows='8' type="textarea"></el-input>
                </el-form-item> 
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onClickCommitAddSenWord">确 定</el-button>
                <el-button icon="el-icon-close" @click="dialogAddSenWord = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- ========分组管理弹框END====== -->
	</el-row>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
    import axios from 'axios'
    import tool from '../../tool.js'
    import config from '../../config.js';

	export default {
		props: ['pkgtype'],
		data() {
			return {
                riskmgtApi: null,
				serverRows: [],
                formTitle: '',
                ///////////////
                dialogWordMgr: false,
                dialogAddSenWord: false,
                senwords: [],
                curPage: 1,
                curSenWordType: 3,
                curKeyWord: '',
                totalSenword: 0,
                formWord: {
                    flag: '',
                    words: '',
                },
                ntypes: [{id:2,name:'欺诈词库'},{id:3,name:'敏感词库'},{id:9,name:'昵称词库'}],
                ///////////////
                formLog: {
                    page: 1,
                    server: '',
                    channel: 'world',
                    queryDate: [],
                },      
                totalChatLog: 0,
				dialogLogForm: false,
                chatLog: [],
                channels: [{id:'world',name:'世界频道'},{id:'game',name:'战斗频道'}],
                choiceDate0: null, // 已选第一个日期
                realtimeChatLog: false, // 标识查看实时聊天
                chatLogTimer: null, // 实时聊天定时器
                pickerOptions: {
                    // 设置不能选择的日期
                    onPick: ({ maxDate, minDate }) => {
                        console.log('onPick', maxDate, minDate);
                        if (maxDate) {
                            this.choiceDate0 = null;
                        } else {
                            this.choiceDate0 = minDate;
                        }
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate0) {
                            return tool.dateMonth(time)!=tool.dateMonth(this.choiceDate0);
                        } else {
                            return false;
                        }
                    }
                },
                //////////////
                formLabelWidth: '124px',
                serverGroups:[],
                curGroupId: -1, // 当前选择分组(默认全部)
                curGroupLabel: "全部区服列表", // 当前选择分组名,
                curNType: -1,
                tableHeight: document.documentElement.clientHeight-136,
                serverStates: config.serverStates,
            }
		},
		created: function() {
			this.refreshServerList()
            this.refreshGroupList()
		},
		computed: {
			...mapGetters(
				['gameHttp']
			),       
		},
		methods: {
            // 发送请求到风控中心
            riskmgtHttp (cmd, data) {
                let rqs = JSON.parse(JSON.stringify(data));
                rqs.cmd = cmd;
                return this.riskmgtApi({
                    method: 'post',
                    url: '/syriskmgt',
                    data: rqs
                });
            },
			// 刷新列表
			refreshServerList() {
				this.gameHttp(3081, {groupid:this.curGroupId}).then((response) => {
					this.serverRows = response.data.list;
                    this.riskmgtApi = axios.create({
                        baseURL: response.data.api,
                        timeout: 5000
                    });
				})
			},
            // 刷新分组列表
            refreshGroupList() {
                this.gameHttp(2006, {plain: 1}).then((response) => {
                    let list = response.data.list
                    this.serverGroups = [{value: -1,label: "全部区服列表"}]
                    for (let i=0; i<list.length; i++) {
                        this.serverGroups.push( {value:list[i].id, label:list[i].name} )
                    }
                })
            },                           
            // 选择服务器分组
            onSelectGroup(item) {
                this.curGroupLabel = item.label
                this.curGroupId = item.value
                // 刷新选择结果
                this.refreshServerList()
            }, 
            // 打开聊天记录（历史）
            onClickChatLogHis(row) {
                this.formTitle = "【"+row.title+"】聊天记录查询";
                this.dialogLogForm = true;
                this.formLog.server = row.name;
                this.chatLog = [];
                this.formLog.queryDate = [];
            },
            // 点击查询按钮
            onClickQueryLog() {
                this.onQueryChatLog();  
            },
            // 查询聊天记录
            onQueryChatLog() {
				this.riskmgtHttp(1001,
                    {   page: this.formLog.page,
                        pernum: 1000,
                        tabletime: tool.dateYM(this.formLog.queryDate[0]),
                        server: this.formLog.server,
                        channel: this.formLog.channel,
                        fromtime: tool.dateFormat(this.formLog.queryDate[0]),
                        totime:tool.dateFormat(this.formLog.queryDate[1]),
                        }).then((response) => {
                            this.chatLog = response.data.list;
                            this.totalChatLog = response.data.total;
                    })
            },
            // 聊天记录分页查看
            handlePageChatLog(page) {
                this.formLog.page = page;
                this.onQueryChatLog();
            },
            // 打开了聊天记录（实时）
            onClickChatLogNow(row) {
                this.formTitle = "【"+row.title+"】实时聊天信息";
                this.dialogLogForm = true;
                this.realtimeChatLog = true;
                this.formLog.server = row.name;
                // 暂时定时刷新
                this.chatLogTimer = setInterval(() => {
                    this.riskmgtHttp(1002, {
                        tabletime: tool.dateYM(new Date()),
                        server: this.formLog.server,
                        channel: this.formLog.channel,
                    }).then((response) => {
                        this.chatLog = response.data.list;
                        // 滚动到底部
                        setTimeout(()=>{
                            this.$refs.dialogTable.bodyWrapper.scrollTop = this.$refs.dialogTable.bodyWrapper.scrollHeight;                          
                        }, 10);
                    })
                    }, 1000);
            },
            // 关闭日志框
            onCloseLog() {
                this.dialogLogForm = false;  
                this.realtimeChatLog = false;
                clearInterval(this.chatLogTimer);
            },
            // 刷新词库
            onRefreshSenword() {
				this.riskmgtHttp(1011, {
                    flag: this.curSenWordType,
                    keyword: this.curKeyWord,
                    page:this.curPage,
                    }).then((response) => {
					this.senwords = response.data.list;
                    this.totalSenword = response.data.total;
				});                
            },
            // 打开词库管理
            onClickWordMgr() {
                this.dialogWordMgr = true;
                this.onRefreshSenword();
            },
            // 词条分页查看
            handleCurrentChange(page) {
                this.curPage = page;
                this.onRefreshSenword();
            },
            // 切换词库类型监听
            onSelectSenwordType() {
                this.curPage = 1;
                this.onRefreshSenword();
            },
            // 点击添加词库
            onClickAddSenWord () {
                this.dialogAddSenWord = true;  
                this.formWord.flag = '';
                this.formWord.words = '';
            },
            // 点击添加词库
            onClickCommitAddSenWord () {
                if (!this.formWord.flag) {
                    this.$message.error('请选择词条类型'); return;
                }
                if (!this.formWord.words) {
                    this.$message.error('请输入词条'); return;
                }
                let arr = this.formWord.words.split('\n');
                let words = [];
                for (let i=0; i<arr.length; i++) {
                    let item = arr[i].trim();
                    if (item) { words.push(item); }
                }
                if (0 == words.length) {
                    this.$message.error('无效的词条'); return;
                }
				this.riskmgtHttp(1012, {
                    flag: this.formWord.flag,
                    words: words,
                    }).then(() => {
                        this.$message.success("提交成功!");
				});
                this.dialogAddSenWord = false; 
            },
            // 点击添加词库
            onClickDelSenword (row) {
                this.$confirm('是否删除[ '+row.word+' ]词条?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.riskmgtHttp(1013, {
                        flag: this.curSenWordType,
                        id: row.id
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("删除成功")
                            this.onRefreshSenword();
                        }
                    })
                }).catch(() => {})
            },            
            // 状态列的标签格式化显示
            stateTagFormatEffect(statev) {
                return (2 == statev) ? "light" : "dark"
            },
            stateTagFormatTxt(statev) {
                return this.serverStates[statev - 1].label
            },
            stateTagFormatColor(statev) {
                return this.serverStates[statev - 1].tag
            },
            // 日志表标色
            logRowClassName({
                row
            }) {
                if (2 == row.flag) {
                    return 'log-error-row';
                } else if (3 == row.flag) {
                    return 'log-warnlog-row';
                } else if (0 == row.flag) {
                    return 'log-graylog-row';
                }
            },
			// 数据表色彩间隔效果     
			tableRowClassName({
				rowIndex
			}) {
				if (0 == rowIndex % 2) {
					return 'warning-row';
				} else {
					return 'success-row';
				}
			},
		},
	}
</script>

<style>
	.serverlist.el-table .warning-row {
		background: #f5f7fa;
	}

	.serverlist.el-table .success-row {
		background: #ffffff;
	}

	.serverlist.el-table {
		margin-top: 16px;
	}

	.serverlist {
		font-size: 12px;
	} 
    
    .serverlist.el-table .log-error-row {
        color: #aa0000;
    }   
    .serverlist.el-table .log-warnlog-row {
        color: #ff5500;
    } 
    
    .serverlist.el-table .log-graylog-row {
        color: #aa557f;
    }
</style>
